// Susy Defaults
$susy: (
        output: float, // float | isolate
        math: fluid, // fluid | static (requires column-width)
        last-flow: to, // float the last element in a row either left (from) or right (to)
        columns: 14, // like bootstrap - see https://www.youtube.com/watch?v=XYtf_3tpVG0 5:00
        gutters: 1/4,
        gutter-position: right,
        global-box-sizing: border-box, //  use the modern border-box method
        container: 1200px,
        container-position: center, // adds room to the left and right of the container
        flow: ltr, // ltr | rtl

  // the Overlay Grid - http://www.drivingmenuts.net/a-trick-for-working-with-susy/
  // this will always show whatever your default $susy columns (above) is set to, unless you override locally
        debug: (
                image: hide,
                color: rgba(100,100,100,0.5),
                output: background, // background | overlay
                toggle: top right
        ),
);

// Set up our Media Queries
// Mobile-first, so anything not in a breakpoint query will be <480px (mobile)
$small: 480px;
$medium: 960px;
$wide: 1200px;

* {
  // in conjunction with global-box-sizing (above), this sets border-box sizing throughout the site
  @include border-box-sizing;
}

// COLOURS

$hp-red: rgb(250,9,9);
$hp-base: rgb(80,80,80);
$hp-wash: rgb(251,251,247);
$hp-light: rgb(240,240,240);
$hp-cloud: rgba(255,255,255,0.7);
$hp-highlight: rgba(255,255,0,0.9);
$hp-highlight-lite: rgba(255,255,0,0.5);
$hp-overcast: darken($hp-light, 5%);
$hp-white: rgb(255,255,255);
