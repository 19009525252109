// Base Layout rules - can be overridden in _pages.scss

body {
  // allow content to clear the info panel strip
  margin-bottom: 5em;
}

.container {
  @include container();
}

main {
  // allow in-page content to clear the common-info panel
  @include span(12 of 14 last);
  @include squish(1);
  margin-top: 4em;
  margin-bottom: 5em;

  &.full {
    @include full();
    @include squish(0);
  }
}

// TEMPLATES

// Text Gallery

.template_text-gallery {
  .tiled-gallery {
    a {
      @include gallery (1 of 2);
    }
  }
}

// Wider templates
.template_full {

}

// Spheres

.template_spheres {
  .project-presentation {
    div {
      @include gallery(6 of 12);
      margin-bottom: 1em;
    }
  }
}

.template_projecthome {
  .player-controls {
    display: none;
  }
}

.template_img-and-audio {
  .project-presentation {
    margin-bottom: 3em;
  }
}