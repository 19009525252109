// Lightcase

.lightcase-icon-close{
  text-decoration: none;
}

#lightcase-case {
  font-family: $font-body!important;

  // NOTE: make sure lightcases contain a .container div
  .slide-inner {
    @include span(12 of 14 last);
    @include squish(1);
  }

  img {
    width: 100%;
    height: auto;
  }

  h4 {
    margin-bottom: 0;
  }

  ul {
    color: $hp-base;
    margin-bottom: 1em;
    padding: 0;
    list-style: none inside;
  }
}

#lightcase-nav {
  a {
    text-decoration: none;
  }
}

// Plyr

.plyr-controls {
  text-decoration: none;
  color: $hp-base;
}

//Flexslider

.flexslider {
  &.manyslides {
   .flex-control-nav {
     display: none;

     @include susy-breakpoint($medium) {
       display: block;
     }
   }
  }
}

// Remodal
.remodal {
  margin-bottom: 5em!important;
}