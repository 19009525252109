// Front Page

.home {
  background-color: $hp-wash;
}

.connections {
  position: absolute;
  height: 1500px;
  width: 2400px;
  margin: 25px;
  font-family: $font-title;

  .connection {
    position: absolute;
    color: white;
    overflow: hidden;
    line-height: 0;
    height: auto;
    width: 300px;
    //transition: box-shadow 0.3s ease-in;

    &:hover {
      //-moz-box-shadow: 3px 3px 0px #939388;
      //-webkit-box-shadow: 3px 3px 0px #939388;
      //box-shadow: 3px 3px 0px #939388;
    }

    >img {
      height: auto;
      width: 100%;
    }
  }
}

// SVG Paths

.connections svg {
  position: absolute;
  path {
    visibility: hidden;
  }
}

.cxn-01-09, .cxn-09-01 {
  top: 120px;
  left: 100px;
}

.cxn-01-14, .cxn-14-01 {
  top: 0;
  left: 100px;
}

.cxn-03-06, .cxn-06-03 {
  top: 583px;
  left: 346px;
}

.cxn-03-05, .cxn-05-03 {
  top: 583px;
  left: 411px;
}

.cxn-04-08, .cxn-08-04 {
  top: 672px;
  left: 131px;
}

.cxn-05-06, .cxn-06-05 {
  top: 835px;
  left: 346px;
}

.cxn-00-05, .cxn-05-00 {
  top: 740px;
  left: 645px;
}

.cxn-05-08, .cxn-08-05 {
  top: 696px;
  left: 645px;
}

.cxn-05-12, .cxn-12-05 {
  top: 821px;
  left: 640px;
}

.cxn-05-17, .cxn-17-05 {
  top: 821px;
  left: 641px;
}

.cxn-00-06, .cxn-06-00 {
  top: 880px;
  left: 346px;
}

.cxn-07-09, .cxn-09-07 {
  top: 477px;
  left: 906px;
}

.cxn-07-11, .cxn-11-07 {
  top: 477px;
  left: 906px;
}

.cxn-07-14, .cxn-14-07 {
  top: 237px;
  left: 906px;
}

.cxn-07-15, .cxn-15-07 {
  top: 441px;
  left: 916px;
}

.cxn-09-11, .cxn-11-09 {
  top: 714px;
  left: 1135px;
}

.cxn-10-14, .cxn-14-10 {
  top: 239px;
  left: 1461px;
}

.cxn-10-15, .cxn-15-10 {
  top: 383px;
  left: 1461px;
}

.cxn-11-12, .cxn-12-11 {
  top: 714px;
  left: 1616px;
}

.cxn-11-16, .cxn-16-11 {
  top: 699px;
  left: 1616px;
}




// Connection Points

.cxn-01 {
  top: 0px;
  left: 25px;
  width: 200px!important;
  height: auto;
}

.cxn-02 {
  top: 150px;
  left: 310px;
  width: 250px!important;
  height: auto;
}

.cxn-03 {
  top: 475px;
  left: 260px;
}

.cxn-04 {
  top: 680px;
  left: 10px;;
}

.cxn-05 {
  top: 700px;
  left: 500px;;
}

.cxn-00 {
  top: 770px;
  left: 660px;;
}

.cxn-06 {
  top: 950px;
  left: 200px;;
}

.cxn-07 {
  top: 340px;
  left: 760px;;
}

.cxn-08 {
  top: 550px;
  left: 1070px;
}

.cxn-09 {
  top: 880px;
  left: 990px;
}

.cxn-10 {
  top: 140px;
  left: 1330px;
}

.cxn-11 {
  top: 580px;
  left: 1480px;
}

.cxn-12 {
  top: 850px;
  left: 1660px;
}

.cxn-13 {
  top: 20px;
  left: 1980px;
}

.cxn-14 {
  top: 140px;
  left: 1650px;
  width: 200px!important;
}

.cxn-15 {
  top: 330px;
  left: 1830px;
}

.cxn-16 {
  top: 580px;
  left: 1830px;
  width: 200px!important;
}

.cxn-17 {
  top: 910px;
  left: 2030px;
  width: 200px!important;
}

.cxn-18 {
  top: 650px;
  left: 2100px;
  width: 220px!important;
}

.cxn-info {
  position: absolute;
  top: 30px;
  left: -100%;
  text-align: left;
  z-index: 1;
  h3 {
    margin: 0 0 1em 0;
    padding: 0.4em 0.5em;
    line-height: 1.4em;
    background-color: rgba(0,0,0,0.6);
    color: $hp-white;
    border: 0;
    font-weight: bold;
    font-size: 1em;
  }

  ul {
    list-style: none;
    margin: 0.3em 0 30px 0;
    padding: 0;
    li {
      margin-bottom: 1.2em;
      height: auto;
      font-size: 1.1em;
      a {
        text-decoration: none;
        font-weight: 200;
        padding: 0.2em 0.4em;
        color: black;
        background-color: lighten($hp-highlight, 20%);
        &:hover {
          background-color: $hp-highlight;
        }

        // icons
        img {
          padding-right: 0.2em;
          margin-bottom: -4px;
          height: 20px;
          width: auto;
        }
      }
    }
  }
}

// Piercefield House

.piercefield_house {
  .feature-image {
    background-image: url('/content/projects/piercefield-house/house/house.jpg');
  }

  section {
    margin-bottom: 2em;
    @include full();

    @include susy-breakpoint($medium) {
      @include span(8 of 12 last);
      @include squish(2 of 12);
    }
  }

  .tiled-gallery {
    a {
      @include gallery(1 of 3);

      @include susy-breakpoint($medium) {
        @include gallery(1 of 5);
      }
    }
  }
}

// Participants Photos

.template_participants {
  .project-presentation {
    @include susy-breakpoint($medium, 12) {
      @include span(6 of 12 last);
      @include squish(3);
    }
  }
}

// Portrait of Nathaniel Wells

.template_portrait {
  .project-presentation {
    @include susy-breakpoint($medium, 12) {
      @include span(8 of 12 last);
      @include squish(2);
    }
  }
}

// About Page

.template_about {
  .project-information {
    @include susy-breakpoint($medium) {
      @include span(8 of 12 last);
      @include squish(2 of 12);
    }
  }
}

//Exploring Piercefield

.template_photosynths {
  .project-presentation {
    @include susy-breakpoint($medium) {
      @include full();
    }
  }
}

.synth {
  @include full();
  margin-bottom: span(1 of 24);
  @include susy-breakpoint($medium) {
    @include gallery(1 of 2);

  }
}

.synth-map {
  position: relative;
  overflow: auto;
  @include susy-breakpoint($medium) {
    @include full();
  }

  >img {
    display: none;
    @include susy-breakpoint($medium) {
      display: block;
    }
  }
}

.synth-tiles {
  display: none;
  @include susy-breakpoint($medium) {
    display: block;
  }

  img {
    display: none;
  }

  .tile-01 {
    position: absolute;
    top: 990px;
    left: 600px;
  }

  .tile-02 {
    position: absolute;
    top: 942px;
    left: 631px;
  }

  .tile-03 {
    position: absolute;
    top: 622px;
    left: 433px;
  }

  .tile-04 {
    position: absolute;
    top: 508px;
    left: 409px;
  }

  .tile-05 {
    position: absolute;
    top: 463px;
    left: 358px;
  }

  .tile-06 {
    position: absolute;
    top: 580px;
    left: 457px;
  }

  .tile-07 {
    position: absolute;
    top: 844px;
    left: 671px;
  }

  .tile-08 {
    position: absolute;
    top: 615px;
    left: 470px;
  }

  .tile-09 {
    position: absolute;
    top: 712px;
    left: 742px;
  }

  .tile-10 {
    position: absolute;
    top: 692px;
    left: 727px;
  }

  .tile-11 {
    position: absolute;
    top: 552px;
    left: 732px;
  }

  .tile-12 {
    position: absolute;
    top: 525px;
    left: 740px;
  }

  .tile-13 {
    position: absolute;
    top: 557px;
    left: 470px;
  }

  .tile-14 {
    position: absolute;
    top: 395px;
    left: 312px;
  }

  .tile-15 {
    position: absolute;
    top: 301px;
    left: 289px;
  }

  .tile-16 {
    position: absolute;
    top: 376px;
    left: 303px;
  }


}

.synth-index {
  list-style: none;
  padding: 0;

  @include susy-breakpoint($medium) {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    z-index: 1;
  }

  li {
    line-height: 2.3em;
    text-align: left;

    @include susy-breakpoint($medium) {
      text-align: right;
    }

    a {
      background-color: rgba(255,255,0,0.9)
    }
  }
}

.synth-frame {
  @include full();
  background-color: black;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//Borders and Environments

.template_map {
  .player {
    // hide this until all audio is in
    display: none;
  }
}

// Educational Resources
.resource {
  border-top: 1px solid darken($hp-light, 10%);
  h2 {
    a {
      text-decoration: none;
    }
    a:hover {
      background-color: $hp-highlight;
    }
    img {
      height: auto;
      margin-left: 0.6em;
      width: 0.8em;
    }
  }
}



