// TYPOGRAPHY

$font-body: "franklin-gothic-urw", sans-serif;
$font-decorative: "monarcha", "franklin-gothic-urw", sans-serif;
$font-title: "proxima-nova-alt", sans-serif;


body {
  font-family: $font-body;
  color: $hp-base;
  line-height: 1.6em;
}

p a, p a:visited {
  color: $hp-base;
  &:hover {
    background-color: $hp-highlight;
  }
}

h1 {
  text-transform: uppercase;
  font-weight: bold;
}

h1,h2 {
  color: $hp-base;
  line-height: 1.2em;
  letter-spacing: 0.03em;
  a {
    color: $hp-base;
  }
}

h1,h2,h3,h4,h5 {
  font-family: $font-title;
}

// in page nav items
.highlight {
  padding: 0.4em 0.5em;
  text-decoration: none;
  color: $hp-base;
  background-color: $hp-highlight-lite;

  &:hover {
    color: black;
    background-color: $hp-highlight;
    border-bottom: 1px solid #e1e1e1;
  }
}


.inline-highlight {
  background-color: $hp-highlight;
}



blockquote {
  margin: 0!important;
  padding-left: 1em;
  border-left: 1px solid #e1e1e1;
  font-size: 0.9em;
  font-style: italic;
}