// Nav Return Button
.nav-return {
  position: fixed;
  top: 60px;
  right: 5px;
  text-align: center;
  line-height: 1;
  font-family: $font-title;
  z-index: 100;

  @include susy-breakpoint($medium, 12) {
    top: 10px;
    right: 10px;
  }

  a {
    float: left;
    padding: 0.3em 0.8em;
    text-decoration: none;
    color: $hp-base;
    background-color: $hp-highlight;

    img {
      margin: 0 0 0 0;
    }

    p {
      margin: 0 0 0.3em 0;
      font-weight: bold;
      font-size: 0.8em;
      display: none;
    }

    @include susy-breakpoint($medium) {
      p {
        font-size: 1em;
        display: block;
      }
    }
  }
}


// Responsive Nav (https://github.com/viljamis/responsive-nav.js)
.nav-toggle {
  margin-top: 1em;
  margin-left: 1em;
  font-family: $font-title;
  text-transform: uppercase;
  color: $hp-base;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 0.05em;

  @include susy-breakpoint($medium) {
    display: none;
  }
}

.nav-collapse {
  margin-left: 1em;
  a {
    font-family: $font-title;
    color: $hp-base;
    text-decoration: none;

  }

  li {
    margin-bottom: 0.5em;
    ul {
      margin-left: 0.5em;
    }
  }

  @include susy-breakpoint($medium) {
    display: none!important;
  }
}


// Information Component
.common-info-panel {
  position: fixed;
  height: 60px;
  width: 100%;
  bottom: 0;
  border-top: 3px solid $hp-highlight;
  background: $hp-white;
  color: black;
  z-index: 10000; // necessary to keep above the draggable map tiles

  h1 {
    font-size: 1em;
  }
  @include susy-breakpoint($medium) {
    h1 {
      font-size: 1.6em;
    }
  }

  &.open {
    .common-info {
      margin-top: 50px;
      overflow-y: auto;
    }
  }
}

.common-info {
  @include container();
}

.common-info-visible {
  @include pre(1);
  height: 60px;
  padding-top: 15px;
  h1 {
    margin: 0;
    line-height: inherit; // align h1 with logo-button
    color: $hp-base;
    font-size: 0.9em;
    a {
      text-decoration: none;
      color: $hp-base;
    }
  }
  @include susy-breakpoint($medium) {
    font-size: 1.4em;
  }
}

.common-info-panel-button {
  float: left;
  margin-right: 2em;
  margin-top: -3px;
}


// - Common Navigation
.nav-main {
  @include full();
  border-bottom: 1px solid $hp-overcast;
  margin-top: 2em;
  margin-bottom: 2em;

  ul {
    font-weight: bold;
    list-style: none;
    padding: 0;
    margin-top: 1.5em;
    li {
      margin-top: 0.6em;
      margin-bottom: 0.6em;
      a {
        text-decoration: none;
        color: $hp-base;
      }
      a:hover {
        font-weight: bold;
      }

      ul {
        font-weight: normal;
        margin: 0;
      }
    }
    li.active {
      font-weight: bold;
    }
  }
}


.info-content {
  @include pre(1);
  @include post(1);

  margin-top: 2em;;

  .about {
    margin-bottom: 3em;
  }

  .partner-logos {
    a {
      @include span(1 of 1 last);

      @include susy-breakpoint($medium) {
        @include gallery(1 of 3);
      }
    }
  }
}

// Project Overviews

// full bleed feature image - no longer needed

.feature-image {
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-position: center top;
  background-size: cover;
  img {
    width: 100%;
    height: auto;
  }
}

// written part
.overview-content {
  @include span(12 of 12 last);
  padding-left: span(1);
  padding-right: span(1);
  padding-top: 3em;
  padding-bottom: 10em;

  @include susy-breakpoint($medium, 12) {
    background-color: $hp-cloud;
  }

  h1 {
    font-size: 1.4em;

    @include susy-breakpoint($medium) {
      font-size: 1.6em;
    }
  }

  h2 {
    font-size: 1.3em;

    @include susy-breakpoint($medium) {
      font-size: 1.5em;
    }
  }


  .overview-projects {
    margin-top: 2em;
    border-top: 1px solid $hp-overcast;
    padding-top: 1em;

    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 0.5em;
      }
    }
  }
}

// pictorial part - delete this as replacing with .feature-image
.overview-img {
  @include span(12 of 12 last);
  margin-top: 2em;

  @include susy-breakpoint($medium, 12) {
    @include pre(1);
    @include span(5 of 12 last);
  }

  img {
    width: 100%;
    height: auto;
  }
}

// this is a box-out style, not currently in use, but might come in handy
.project-participants {
  margin: 3em 0 0 0;
  padding: 0 1em 1em 1em;
  border: 1px solid rgb(235,235,235);
  background-color: rgb(245,245,245);
}


// Gallery Areas

// make these less template-specific at some point

.project-presentation {
  @include span(10 of 12 last);
  @include squish(1);

  @include susy-breakpoint($medium, 12) {
    @include span(8 of 12 last);
    @include squish(2);
  }
}

.project-information {
  @include span(10 of 12 last);
  @include squish(1);
  margin-top: 1em;
  margin-bottom: 1em;

  @include susy-breakpoint($medium, 12) {
    @include span(8 of 12 last);
    @include squish(2);
    margin-top: 2em;
  }

  h1 {
    margin-top: 0.5em;
    text-transform: none;
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}

.project-name {
  text-transform: uppercase;
  font-family: $font-title;
  margin: 0;
  letter-spacing: 0.1em;
  font-size: 0.8em;
}

.tiled-gallery {
  a {
    @include gallery(1 of 2);
    margin-bottom: 3em;

    @include susy-breakpoint($medium, 12) {
      @include gallery(1 of 3);
      margin-bottom: 2.5em;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

}

// Styling for Lightcase containing multimedia content (pics, sound and text)
.template_slides-multimedia {
  .slide-img {
    @include span(12 of 12 last);
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .slide-audio {
    @include full();
  }

  .slide-content {
    @include pre(1 of 12);
    @include post(1 of 12);
    @include span(10 of 12);
    margin-top: 1em;
    font-size: 1.2em;
  }
}

// Fixed Width Areas

// Project information sits centrally under main presentation instead of to the side
.fixed_grid {
  .project-information {
    @include span(12 of 12 last);
    margin-top: 1em;

    @include susy-breakpoint($medium, 12) {
      @include span(6 of 12 last);
      @include squish(3);
      margin-top: 1em;
    }
  }
}

// Project Icons

.project-icon {
  padding-right: .2em;
  margin-bottom: -4px;
  height: 20px;
  width: auto;
}



// Drag and Drop Map

.map-container {
  width: 900px;
  height: 850px;
  margin: 2em auto 300px auto;
}

.map-dropzone {
  height:1018px;
  width: 900px;
  overflow: visible;
  padding: 0;
  position: relative;

  .map-palette {
    @include container();

    .map-bay {
      @include gallery(1 of 10);
    }
  }

  .map-canvas {
    height: 848px;
    width: 900px;
    position: absolute;
    top: 170px;
    background-color: white;

    .map-cell {
      border: 1px dashed #e1e1e1;
      position: relative;
      display: inline-block;
      height: 424px;
      width: 300px;
      margin-left: -5px;
      margin-bottom: -8px;
      top: 0px;
      left: 0;
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
  }
}

.tile {
  width: 70px;
  height: auto;
  position: relative;
  top: 0px;
  margin: 0px 8px;
}


// Simple audio file Plyr

.template_map {
  .player {
    min-width: 0;
    width: 70px;
    margin: 0 8px;

    .player-controls {
      background: $hp-wash;
      .player-controls-left {
        float: none;
      }
    }
  }
}

.audioplayer-medium {
  @include span(10 of 12);
  @include squish(1 of 12);

  @include susy-breakpoint($medium) {
    @include span(8 of 12 last);
    @include squish(2 of 12);
  }
}


// Popups - don't recall what this is (could belong in _classes.scss)

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

